<template>
  <div>

    <div class="mx-2 text-secondary">
      <span class="font-weight-bolder">Connections</span> refers to credential based system communications that may be used to perform operations on Flux.
      <p>
         <b-icon icon="arrow-return-right" class="ml-75 mr-25" scale="1"/>
         things such as <span class="font-weight-bolder">login to Databases</span> (ex: MySQL, PostgresSQL, MongoDB) or <span class="font-weight-bolder">FTP</span>s </p>
    </div>

    
    <div class="mt-1" align="right">
      <b-button variant="success" @click="$refs['create-connection-modal'].show()">
        <b-icon icon="plus" scale="1.3" class="mr-25"/>
        Create Connection
      </b-button>
    </div>
    
    <div class="mt-2" ref="connection-scroll-basis">
      <connection-info ref="connection-info" :data="currentlyOpenConnectionInfo" @closingInfo="currentlyOpenConnectionInfo = undefined" @openDeleteModal="openDeleteModal"/>
    </div>

    <h4>Connections:</h4>
  
    <div class="overflow-hidden rounded" v-if="connections">
      <b-table
        show-empty 
        :items="connections" 
        :fields="tableFields"
        class="m-0"
        sort-icon-left
        @row-clicked="openConnectionInfo"
        hover
        sort-by="id"
        :sort-desc="true"
      >

        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <b-icon icon="eye" scale="1" :variant="(currentlyOpenConnectionInfo && currentlyOpenConnectionInfo.id == data.item.id) ? 'success' : 'secondary '" class="mr-1" />
            <h5>{{data.item.name}}</h5>
          </div>
        </template>

        
        <template #cell(enum_connection_type_id)="data">
          <b-badge variant="secondary" class="px-25 text-black" :style="`background-color: ${getTypeByID(data.item.enum_connection_type_id).color};`">
            <span class="text-light">{{$t(getTypeByID(data.item.enum_connection_type_id).label)}}</span>
          </b-badge>
        </template>

        <template #cell(host)="data">
          <span class="text-secondary">{{data.item.host}}</span>
        </template>

        <template #cell(enum_status_id)="data">
          <b-badge :variant="getEnumStatus(data.item.enum_status_id).variant" pill>{{ getEnumStatus(data.item.enum_status_id).label }}</b-badge> 
        </template>


        <template #head(_)="data"><span/></template>
        <template #cell(_)="data">
          <div align="right" class="w-250px float-right d-flex justify-content-end align-items-center">   
            <div v-if="shownOptions==data.item.id" class="animate__faster animate__fadeInRight animate__animated " >
              <b-button variant="outline-danger" class="py-25 px-50 animate__faster animate__fadeInRight animate__animated" @click="openDeleteModal(data.item)">
                <b-icon icon="trash" class="mr-25"/> Delete
              </b-button>

            </div>
            
            <b-button variant="none" class="py-25 px-50" @click="shownOptions = data.item.id">
              <b-icon icon="three-dots-vertical" scale="1.1" />
            </b-button>
          </div>
        </template>

        <template #empty> 
          <div align="center" class="text-secondary">
            <h3 class="text-secondary my-2">No Connections yet</h3>
          </div>
        </template>
     
      </b-table>
    </div>
    <div v-else class="overflow-hidden rounded">
      <b-skeleton-table animation="fade" class="m-0" :rows="5"/>
    </div>

    <b-modal 
      ref="delete-connection-modal"
      centered
      title="Deleting Connection"
      lazy
      ok-variant="danger"
      :ok-disabled="deleteLoading"
      auto-focus-button="ok"
      @ok="deleteConnection()"
      @hide="((e)=>{ if (deleteLoading) e.preventDefault()})"
    >
      <template v-if="currentDeletingItem">
        Are you sure you want to delete the connection <span class="font-weight-bolder text-favorite">{{currentDeletingItem.name}}</span>?
      </template>
      <template #modal-ok>
        <div>
          <span v-if="!deleteLoading">Confirm</span>
          <b-spinner v-else small />
        </div>
      </template>
    </b-modal>

    <create-connection-modal ref="create-connection-modal"></create-connection-modal>

  </div>
</template>

<script>

import { mapGetters } from "vuex";
import ConnectionTypes from '@/custom/class/Enum/ConnectionTypes.js'
import createConnectionModal from "@/views/pages/middleware/MiddlewareManagement/Components/Modals/CreateConnectionModal.vue"
import connectionStatus from '@/custom/class/Enum/ConnectionStatus.js'
import { makeToast } from '@/layouts/components/Popups';
import connectionInfo from '@/views/pages/flux_connections/ConnectionInfo.vue'

import {
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BButton,
  BTable,
  BBadge,
  BSpinner,
  BCollapse,
  BCard,
  BSkeletonTable
} from "bootstrap-vue";

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    createConnectionModal,
    BSpinner,
    BCollapse,
    BCard,
    connectionInfo,
    BSkeletonTable
  },
  data() {
    return {
      shownOptions: undefined,
      tableFields:[
        {
          key: 'name',
          label: "Name",
          sortable: true,
        },
        {
          key: 'enum_connection_type_id',
          label: "Type",
          sortable: true,
        },
        {
          key: 'host',
          label: "Host",
          sortable: true,
        },
        {
          key: 'enum_status_id',
          label: "Status",
          sortable: true,
        },
        {
          key: '_',
          label: "d",
          sortable: false,
        },
      ],
      currentDeletingItem : undefined,
      deleteLoading: false,
      currentlyOpenConnectionInfo: undefined
    }
  },
  computed: {
    ...mapGetters('connection',['getConnections']),
    connections(){
      return this.getConnections
    },
    organizationID() {
      return localStorage.getItem('selectedOrganization')
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    init(){
      this.$store.dispatch('connection/getOrgConnections', {organization: this.organizationID })
    },
    openConnectionInfo(item){
      this.$refs['connection-info'].reset()
      this.$refs['connection-scroll-basis'].scrollIntoView({behavior: 'smooth'});

      this.$store.dispatch('connection/getConnectionInfoByID',{organization: this.organizationID , connection: item.id}).then((resp)=>{        
        this.currentlyOpenConnectionInfo = resp
        this.$route.params.connectionID = item.id
      })
    },
    getTypeByID(id){
      let type = new ConnectionTypes().items.find(el=>el.id == id)
      return type
    },
    getEnumStatus(id){
      let item = new connectionStatus().items.find(el=>el.id == id)
      return item
    },
    openDeleteModal(item){
      this.currentDeletingItem = structuredClone(item)
      this.$refs['delete-connection-modal'].show()
    },
    deleteConnection(){
      this.deleteLoading = true
      let item = structuredClone(this.currentDeletingItem)

      this.$store.dispatch("connection/deleteConnection", {organization: this.organizationID, connection: item.id}).then((resp)=>{

      this.deleteLoading = false
        
      if (this.currentlyOpenConnectionInfo && this.currentlyOpenConnectionInfo.id == item.id){
        this.currentlyOpenConnectionInfo = undefined
      }

      this.$nextTick(()=>{
        this.$refs['delete-connection-modal'].hide()
      })
      
      makeToast({
        title: `Success!`,
        text: `Connection ${item.name} deleted successfully`,
        variant: 'success',
        icon: 'CheckIcon'
      })
      
      })
    }
  }
};
</script>

<style>

.w-250px{
  width: 250px;
}



</style>